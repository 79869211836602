export const MANUAL_FETCH_LIST = "MANUAL_FETCH_LIST";
export const MANUAL_FETCH_LIST_SUCCESS = "MANUAL_FETCH_LIST_SUCCESS";

export const MANUAL_FETCH_ITEM_BY_ID = "MANUAL_FETCH_ITEM_BY_ID";
export const MANUAL_FETCH_ITEM_BY_ID_SUCCESS = "MANUAL_FETCH_ITEM_BY_ID_SUCCESS";

export const MANUAL_CREATE_UPDATE = "MANUAL_CREATE_UPDATE";
export const MANUAL_CREATE_UPDATE_SUCCESS = "MANUAL_CREATE_UPDATE_SUCCESS";

export const MANUAL_DELETE = "MANUAL_DELETE";
export const MANUAL_DELETE_SUCCESS = "MANUAL_DELETE_SUCCESS";

export const MANUAL_UPDATE = "MANUAL_UPDATE";
export const MANUAL_UPDATE_SUCCESS = "MANUAL_UPDATE_SUCCESS";

export const MANUAL_PAYMENT = "MANUAL_PAYMENT";
export const MANUAL_PAYMENT_SUCCESS = "MANUAL_PAYMENT_SUCCESS";


export const MANUAL_FETCH_LIST_RUN = "MANUAL_FETCH_LIST_RUN";
export const MANUAL_FETCH_LIST_RUN_SUCCESS = "MANUAL_FETCH_LIST_RUN_SUCCESS";


export const MANUAL_FETCH_LIST_HISTORY_RUN = "MANUAL_FETCH_LIST_HISTORY_RUN";
export const MANUAL_FETCH_LIST_HISTORY_RUN_SUCCESS = "MANUAL_FETCH_LIST_HISTORY_RUN_SUCCESS";


export const MANUAL_AUTOCOMPLETE_SEARCH = "MANUAL_AUTOCOMPLETE_SEARCH";
export const MANUAL_AUTOCOMPLETE_SEARCH_SUCCESS = "MANUAL_AUTOCOMPLETE_SEARCH_SUCCESS";

export const MANUAL_SEARCH_HDSD = "MANUAL_SEARCH_HDSD";
export const MANUAL_SEARCH_HDSD_SUCCESS = "MANUAL_SEARCH_HDSD_SUCCESS";

export const MANUAL_FETCH_ITEM_BY_SLUG = "MANUAL_FETCH_ITEM_BY_SLUG";
export const MANUAL_FETCH_ITEM_BY_SLUG_SUCCESS = "MANUAL_FETCH_ITEM_BY_SLUG_SUCCESS";


export const DETAIL_MACHINE_BY_ID = "DETAIL_MACHINE_BY_ID";
export const DETAIL_MACHINE_BY_ID_SUCCESS = "DETAIL_MACHINE_BY_ID_SUCCESS";