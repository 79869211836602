import * as contant_types from '../contants/manual';

export const handleFetchList = (requets = {}, token) => ({
    type: contant_types.MANUAL_FETCH_LIST,
    payload: {
        requets,
        token
    }
})

export const handleFetchListSuccess = data => ({
    type: contant_types.MANUAL_FETCH_LIST_SUCCESS,
    payload: {
        data
    }
})

export const handleFetchItemById = (id, token) => ({
    type: contant_types.MANUAL_FETCH_ITEM_BY_ID,
    payload: {
        id,
        token
    }
})

export const handleFetchItemByIdSuccess = data => ({
    type: contant_types.MANUAL_FETCH_ITEM_BY_ID_SUCCESS,
    payload: {
        data
    }
})

export const handleCreateUpdate = (form_data, token) => ({
    type: contant_types.MANUAL_CREATE_UPDATE,
    payload: {
        form_data,
        token
    }
})

export const handleCreateUpdateSuccess = data => ({
    type: contant_types.MANUAL_CREATE_UPDATE_SUCCESS,
    payload: {
        data
    }
})

export const handleDelete = (id, token) => ({
    type: contant_types.MANUAL_DELETE,
    payload: {
        id,
        token
    }
})

export const handleDeleteSuccess = data => ({
    type: contant_types.MANUAL_DELETE_SUCCESS,
    payload: {
        data
    }
})


export const handleUpdate = (requets = {}, token) => ({
    type: contant_types.MANUAL_UPDATE,
    payload: {
        requets,
        token
    }
})

export const handleUpdateSuccess = data => ({
    type: contant_types.MANUAL_UPDATE_SUCCESS,
    payload: {
        data
    }
})

export const handlePayment = (id, token) => ({
    type: contant_types.MANUAL_PAYMENT,
    payload: {
        id,
        token
    }
})

export const handlePaymentSuccess = data => ({
    type: contant_types.MANUAL_PAYMENT_SUCCESS,
    payload: {
        data
    }
})

export const handleFetchListRun = (requets = {}, token) => ({
    type: contant_types.MANUAL_FETCH_LIST_RUN,
    payload: {
        requets,
        token
    }
})

export const handleFetchListRunSuccess = data => ({
    type: contant_types.MANUAL_FETCH_LIST_RUN_SUCCESS,
    payload: {
        data
    }
})

export const handleAutocomplete = (requets = {}) => ({
    type: contant_types.MANUAL_AUTOCOMPLETE_SEARCH,
    payload: {
        requets
    }
})

export const handleAutocompleteSuccess = data => ({
    type: contant_types.MANUAL_AUTOCOMPLETE_SEARCH_SUCCESS,
    payload: {
        data
    }
})

export const handleSearch = (requets = {}) => ({
    type: contant_types.MANUAL_SEARCH_HDSD,
    payload: {
        requets
    }
})

export const handleSearchSuccess = data => ({
    type: contant_types.MANUAL_SEARCH_HDSD_SUCCESS,
    payload: {
        data
    }
})

export const handleFecthItemBySlug = (requets = {}) => ({
    type: contant_types.MANUAL_FETCH_ITEM_BY_SLUG,
    payload: {
        requets
    }
})

export const handleFecthItemBySlugSuccess = data => ({
    type: contant_types.MANUAL_FETCH_ITEM_BY_SLUG_SUCCESS,
    payload: {
        data
    }
})

export const handleDetailMachineById = (id) => ({
    type: contant_types.DETAIL_MACHINE_BY_ID,
    payload: {
        id
    }
})

export const handleDetailMachineByIdSuccess = data => ({
    type: contant_types.DETAIL_MACHINE_BY_ID_SUCCESS,
    payload: {
        data
    }
})